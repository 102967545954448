import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import _ from "lodash";
import { useEffect } from "react";
import { getJSON } from "./utils";

import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Data from "./pages/Data";
import Profile from "./pages/Profile";
import Billing from "./pages/Billing";
import ProtectedRoute from "./ProtectedRoute";
import RestPassword from "./pages/RestPassword";
import Partners from "./pages/Partners";
import Reservation from "./pages/Reservation";
import Sliders from "./pages/Sliders";
import Services from "./pages/Services";
import Team from "./pages/Team";
import Contacts from "./pages/Contacts";
import Opinions from "./pages/Opinions";
import Article from "./pages/Article";
import Semulations from "./pages/Semulations";
import Config from "./pages/Config";

import Page from "./pages/Pages";
import Navigation from "./pages/Navigation";


import Users from "./pages/Users";
import Projects from "./pages/Projects";


import HomeEvents from "./pagesEvents/Home";
import TablesEvents from "./pagesEvents/Tables";
import DataEvents from "./pagesEvents/Data";
import ProfileEvents from "./pagesEvents/Profile";
import BillingEvents from "./pagesEvents/Billing";
import PartnersEvents from "./pagesEvents/Partners";
import ReservationEvents from "./pagesEvents/Reservation";
import SlidersEvents from "./pagesEvents/Sliders";
import ServicesEvents from "./pagesEvents/Services";
import TeamEvents from "./pagesEvents/Team";
import ContactsEvents from "./pagesEvents/Contacts";
import OpinionsEvents from "./pagesEvents/Opinions";
import ArticleEvents from "./pagesEvents/Article";
import SemulationsEvents from "./pagesEvents/Semulations";
import ConfigEvents from "./pagesEvents/Config";
import PageEvents from "./pagesEvents/Pages";
import NavigationEvents from "./pagesEvents/Navigation";

import UsersEvents from "./pagesEvents/Users";
import ProjectsEvents from "./pagesEvents/Projects";

function App() {
  if (!localStorage.getItem("token") || !getJSON(localStorage.getItem("token")))
    <Navigate to="/sign-in" />;
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Navigate to="/dashboard" />} />
          <Route path="/resetpassword/:id/:token" element={<RestPassword />} />

          {/* <Route path="/sign-up" element={<SignUp />} /> */}
          <Route path="/sign-in" element={<SignIn />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<Main />}>
              <Route path="/billing" element={<Billing />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/tables" element={<Tables />} />
              <Route path="/partners" element={<Partners />} />
              <Route path="/meeting" element={<Reservation />} />
              <Route path="/opinions" element={<Opinions />} />
              <Route path="/article" element={<Article />} />
              <Route path="/config" element={<Config />} />
              <Route path="/pages" element={<Page />} />
              <Route path="/semulations" element={<Semulations />} />
              <Route path="/contact" element={<Contacts />} />
              <Route path="/services" element={<Services />} />
              <Route path="/about" element={<Data />} />
              <Route path="/sliders" element={<Sliders />} />
              <Route path="/team" element={<Team />} />
              <Route path="/dashboard" element={<Home />} />
              <Route path="/navigation" element={<Navigation />} />
              <Route path="/profile" element={<Profile />} />
              {/* <Route path="/custommail" element={<CustomMailGen />} />
              <Route path="/htmleditor" element={<HtmlEditor />} /> */}
              <Route path="/users" element={<Users />} />

              <Route path="/Events/billing" element={<BillingEvents />} />
              <Route path="/Events/projects" element={<ProjectsEvents />} />
              <Route path="/Events/tables" element={<TablesEvents />} />
              <Route path="/Events/partners" element={<PartnersEvents />} />
              <Route path="/Events/meeting" element={<ReservationEvents />} />
              <Route path="/Events/opinions" element={<OpinionsEvents />} />
              <Route path="/Events/article" element={<ArticleEvents />} />
              <Route path="/Events/config" element={<ConfigEvents />} />
              <Route path="/Events/pages" element={<PageEvents />} />
              <Route
                path="/Events/semulations"
                element={<SemulationsEvents />}
              />
              <Route path="/Events/contact" element={<ContactsEvents />} />
              <Route path="/Events/services" element={<ServicesEvents />} />
              <Route path="/Events/about" element={<DataEvents />} />
              <Route path="/Events/sliders" element={<SlidersEvents />} />
              <Route path="/Events/team" element={<TeamEvents />} />
              <Route path="/Events/dashboard" element={<HomeEvents />} />
              <Route path="/Events/navigation" element={<NavigationEvents />} />
              <Route path="/Events/profile" element={<ProfileEvents />} />
              {/* <Route path="/custommail" element={<CustomMailGenEvents />} />
              <Route path="/htmleditor" element={<HtmlEditorEvents />} /> */}
              <Route path="/Events/users" element={<UsersEvents />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
