import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  message,
  Row,
  Select,
  Upload,
  DatePicker,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { notification } from "antd";
import axios from "axios";
import { getJSON } from "../../utils";
import { VerticalAlignTopOutlined } from "@ant-design/icons";
import _, { isNil } from "lodash";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
const { Option } = Select;

const AddOrUpdateModalGestion = (props) => {
  const { visible, onCancel } = props;
  const [imageURL, setImageURL] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filelist, setfilelist] = useState([]);

  const serverURL = "https://hma-back-xg3n.onrender.com";
  const [form] = useForm();

  useEffect(() => {
    if (props.type === "EDIT") {
      form.setFieldsValue({
        ...props.record,
        deliveredAt:moment(props.record.deliveredAt)
      });
      setfilelist(props.record?.images.length > 0 ? props.record?.images : []);
    } else {
      setfilelist([]);
    }
  }, [form, props.record, props.visible]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = async (info, listfilesuploaded) => {
    if (info.file.uid === _.last(info.fileList).uid) {
      setLoading(true);

      try {
        const listOfPromise = [];
        const newImageArray = [];

        info?.fileList?.forEach((el) => {
          if (el?.originFileObj) {
            var bodyFormData = new FormData();

            bodyFormData.append("image", el?.originFileObj);

            newImageArray.push(
              serverURL + "/images/" + el?.originFileObj?.name
            );

            listOfPromise.push(
              axios({
                method: "post",
                url: serverURL + "/api/upload",
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
              })
            );
          }
        });

        await Promise.all(listOfPromise);
        setfilelist([...listfilesuploaded, ...newImageArray]);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };
  const handleonfinish = async (val) => {
    const config = {
      headers: {
        authorization: getJSON(localStorage.getItem("token")),
      },
    };

    const values = {
      ...val,
      id: props.record._id,
    };

    if (props.type === "EDIT") {
      await axios
        .put(
          "https://hma-back-xg3n.onrender.com/api/projects/edit/" + values.id,
          {
            name: values.name ?? form.getFieldValue("name"),
            category: form.getFieldValue("category"),
            link: form.getFieldValue("link"),
            deliveredAt: form.getFieldValue("deliveredAt"),
            client: form.getFieldValue("client"),
            images: filelist,
            description: form.getFieldValue("description"),
          },
          config
        )
        .then((response) => {
          notification.success({ message: "Update Done  " });
          props.refetech();
          onCancel();
        })
        .catch(function (err) {
          props.refetech();
          onCancel();
        });
    } else {
      console.log("from", form.getFieldValue("data"));
      await axios
        .post(
          "https://hma-back-xg3n.onrender.com/api/projects",
          {
            name: form.getFieldValue("name"),
            category: form.getFieldValue("category"),
            link: form.getFieldValue("link"),
            deliveredAt: form.getFieldValue("deliveredAt"),
            client: form.getFieldValue("client"),
            images: filelist,
            description: form.getFieldValue("description"),
          },
          config
        )
        .then((response) => {
          notification.success({ message: "Create Done  " });
          props.refetech();
          onCancel();
        })
        .catch(function (err) {
          props.refetech();
          onCancel();
        });
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleonfinish}
      preserve={props.type === "EDIT" ? true : false}
    >
      <div className="site-card-border-less-wrapper">
        <Modal
          title={props.type === "EDIT" ? "UPDATE" : "CREATE"}
          centered
          visible={visible}
          destroyOnClose
          onOk={() => {
            form.submit();
          }}
          onCancel={onCancel}
        >
          <Card
            centered
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Row justify="space-between" gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the name!",
                    },
                  ]}
                >
                  <Input placeholder="name" type="texte" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "Please input the category!",
                    },
                  ]}
                >
                  <Input placeholder="category" type="text" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="link"
                  rules={[
                    {
                      required: true,
                      message: "Please input the link!",
                    },
                  ]}
                >
                  <Input placeholder="link" type="text" />
                </Form.Item>
              </Col>
              <Col span={12}>
              
                <Form.Item
                  name="deliveredAt"
                  rules={[
                    {
                      required: true,
                      message: "Please input the category!",
                    },
                  ]}
                >
                <DatePicker placeholder="deliveredAt" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="client"
                  rules={[
                    {
                      required: true,
                      message: "Please input the client!",
                    },
                  ]}
                >
                  <Input placeholder="client" type="text" />
                </Form.Item>
              </Col>
              
              <Col span={24}>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => (
                    <Form.Item
                      name="images"
                      rules={[
                        {
                          required: true,
                          message: "Please input your image!",
                        },
                      ]}
                    >
                      <Upload
                        name="slideimg"
                        listType="picture-card"
                        className="avatar-uploader projects-uploader"
                        showUploadList={true}
                        onChange={(val) => handleChange(val, filelist)}
                        onRemove={(file) => {
                          const index = filelist.indexOf(file.url);
                          const newFileList = filelist.slice();
                          newFileList.splice(index, 1);
                          setfilelist(newFileList);
                        }}
                        fileList={
                          !isNil(filelist)
                            ? filelist?.map((el, i) => ({
                                uid: -i,
                                name: "image.png",
                                status: "done",
                                url: el,
                              }))
                            : []
                        }
                        multiple
                      >
                        {imageURL || props?.record?.image ? (
                          <img
                            src={
                              getFieldValue("images") ?? props?.record?.images
                            }
                            alt="slideimg"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <div className="ant-upload-text font-semibold text-dark">
                            {
                              <VerticalAlignTopOutlined
                                style={{ width: 20, color: "#000" }}
                              />
                            }
                            <div>Upload New Project Image</div>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input the description!",
                    },
                  ]}
                >
                  
                  <TextArea placeholder="description" type="text" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Modal>
      </div>
    </Form>
  );
};

export default AddOrUpdateModalGestion;
