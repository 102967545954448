import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { notification } from "antd";
import axios from "axios";
import TextArea from "antd/lib/input/TextArea";
import { getJSON } from "../../utils";
const { Option } = Select;

const AddOrUpdateModalConfig = (props) => {
  const { visible, onCancel } = props;

  const [form] = useForm();

  useEffect(() => {
    if (props.type === "EDIT") {
      form.setFieldsValue({
        ...props.record,
      });
    } else {
    }
  }, [form, props.record, props.visible]);

  const handleonfinish = async (val) => {
    const config = {
      headers: {
        authorization: getJSON(localStorage.getItem("token")),
      },
    };

    const values = {
      ...val,
      id: props.record._id,
    };

    if (props.type === "EDIT") {
      await axios
        .put(
          "https://hma-back-xg3n.onrender.com/api/configurations/edit/" + values.id,
          {
            email: values.email,
            address: values.address,
            phone: values.phone,

            openingDayStart: values.openingDayStart,
            openingDayEnd: values.openingDayEnd,
            openingHourStart: values.openingHourStart,
            openingHourEnd: values.openingHourEnd,
          
          },
          config
        )
        .then((response) => {
          notification.success({ message: "Update Done  " });
          props.refetech();
          onCancel();
        })
        .catch(function (err) {
          props.refetech();
          onCancel();
        });
    } else {
      console.log("from", form.getFieldValue("data"));
      await axios
        .post(
          "https://hma-back-xg3n.onrender.com/api/configurations/",
          {
            email: form.getFieldValue("email"),
            address: form.getFieldValue("address"),
            phone: form.getFieldValue("phone"),

            
            openingDayStart: form.getFieldValue("openingDayStart"),
            openingDayEnd: form.getFieldValue("openingDayEnd"),
            openingHourStart: form.getFieldValue("openingHourStart"),
            openingHourEnd: form.getFieldValue("openingHourEnd"),

          },
          config
        )
        .then((response) => {
          notification.success({ message: "Create Done  " });
          props.refetech();
          onCancel();
        })
        .catch(function (err) {
          props.refetech();
          onCancel();
        });
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleonfinish}
      preserve={props.type === "EDIT" ? true : false}
    >
      <div className="site-card-border-less-wrapper">
        <Modal
          title={props.type === "EDIT" ? "UPDATE" : "CREATE"}
          centered
          visible={visible}
          destroyOnClose
          onOk={() => {
            form.submit();
          }}
          onCancel={onCancel}
        >
          <Card
            centered
            style={{
              width: "100%",
              height: "100%",
            }}
          >

          <Row justify="space-between" gutter={16}>


              <Col span={24}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="email" type="texte" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone!",
                    },
                  ]}
                >
                  <Input placeholder="phone" type="texte" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Please input your address!",
                    },
                  ]}
                >
                  <Input placeholder="address" type="texte" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="openingDayStart"
                  rules={[
                    {
                      required: true,
                      message: "Please input your address!",
                    },
                  ]}
                >
                  <Input placeholder="openingDayStart" type="texte" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="openingDayEnd"
                  rules={[
                    {
                      required: true,
                      message: "Please input your openingDayEnd!",
                    },
                  ]}
                >
                  <Input placeholder="openingDayEnd" type="texte" />
                </Form.Item>
              </Col>



              <Col span={12}>
                <Form.Item
                  name="openingHourStart"
                  rules={[
                    {
                      required: true,
                      message: "Please input your address!",
                    },
                  ]}
                >
                  <Input placeholder="openingHourStart" type="texte" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="openingHourEnd"
                  rules={[
                    {
                      required: true,
                      message: "Please input your openingHourEnd!",
                    },
                  ]}
                >
                  <Input placeholder="openingHourEnd" type="texte" />
                </Form.Item>
              </Col>


            

            </Row>
          </Card>
        </Modal>
      </div>
    </Form>
  );
};

export default AddOrUpdateModalConfig;
